<template>
  <aside
    :class="{ active: lightbox.active, 'has-items': lightbox.group.length, zoom: lightbox.zoom }"
    class="lightbox"
  >
    <CAtomsButton
      class="lightbox-close"
      type="button"
      icon="IconAdd"
      icon-only
      @click="lightbox.clear()"
    />
    <div class="lightbox-body">
      <div
        class="lightbox-content"
        @click="lightbox.zoom = !lightbox.zoom"
        @keyup.z="lightbox.zoom = !lightbox.zoom"
        @keyup.enter="lightbox.zoom = !lightbox.zoom"
      >
        <template v-if="lightbox.value">
          <transition name="lightbox-content" mode="out-in">
            <CAtomsVideo
              v-if="lightbox.value.type === 'video' && lightbox.value.video"
              v-bind="lightbox.value"
              :key="lightbox.value.video"
              class="lightbox-content-item lightbox-video"
            />
            <CAtomsImage
              v-else-if="lightbox.value.image"
              :key="lightbox.value.image"
              :image="lightbox.value.image"
              class="lightbox-content-item lightbox-image"
            />
          </transition>
        </template>
      </div>
      <transition name="lightbox-content" mode="out-in">
        <span :key="lightbox.value.caption" class="lightbox-caption" v-text="lightbox.value.caption" />
      </transition>
      <template v-if="lightbox.group.length">
        <CAtomsLink
          class="lightbox-prev"
          type="button"
          icon="IconChevron"
          icon-only
          @click="set('prev')"
        />
        <CAtomsLink
          class="lightbox-next"
          type="button"
          icon="IconChevron"
          icon-only
          @click="set('next')"
        />
      </template>
    </div>
    <CBlocksList
      v-if="lightbox.group.length"
      :list="lightbox.group"
      hide-text name="lightbox"
      :active="active"
      action
      :style="{ transform: `translateX(${(- active * 2) + 'rem'})` }"
      @set-click="e => lightbox.set(lightbox.group[e])"
    />
  </aside>
</template>

<script lang="ts" setup>
const { lightbox } = useLightbox()

function set(dir: 'prev' | 'next' | number) {
  if (!lightbox.group.length) return

  const index = lightbox.group.findIndex(image =>
    image.id
      ? image.id === (lightbox.value as SanityImage).id
      : image === lightbox.value
  )

  if (index < 0) return

  const set =
        dir === 'prev'
          ? index - 1 < 0
            ? lightbox.group.length - 1
            : index - 1
          : (index + 1) % lightbox.group.length

  if (lightbox.group[set]) lightbox.set(lightbox.group[set])
}

const active = computed(() => lightbox.group.findIndex(image => image === lightbox.value))

watch(
  () => lightbox.active,
  () => {
    handleEventListener<'keyup'>(lightbox.active, 'keyup', (e) => {
      switch (e.code) {
        case 'KeyA':
        case 'ArrowLeft':
          return set('prev')
        case 'KeyD':
        case 'ArrowRight':
          return set('next')
        case 'KeyW':
        case 'ArrowUp':
          return (lightbox.zoom = true)
        case 'KeyS':
        case 'ArrowDown':
          return (lightbox.zoom = false)
        case 'Escape':
          return lightbox.clear()
      }
    })
    if (lightbox.active) document.documentElement.classList.add('overlay-open')
    else document.documentElement.classList.remove('overlay-open')
  }
)
</script>

<style lang="scss">
// .image-enter-active,
// .image-leave-to {
//   transform: scale(0.5) translateY(100vh);
//   opacity: 0;
// }

body.lightbox-open {
  overflow-y: hidden;
}

.lightbox {
  position: fixed;
  inset: 0;
  z-index: 2000;

  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;

  background: rgba(black, 0.9);

  transition: opacity 0.5s, visibility 0.5s;

  .lightbox-body {
    display: flex;
    flex-flow: column;
    width: 100%;
    flex: 1 1 90vh;
    max-height: 90vh;
    
    padding: 5rem 5vmin 2rem;
    align-items: center;
    justify-content: center;
  }

  &:not(.active) {
    visibility: hidden;
    opacity: 0;

    pointer-events: none;
  }

  &.has-items {
    grid-template-rows: 2fr auto 6rem;
  }

  &.zoom {
    overflow: scroll;

    .lightbox-content {
      align-items: flex-start;
      &-item {
        // max-width: unset;
        max-height: unset;

        cursor: zoom-out;
      }
    }
  }
  &.closed {
    .sidebar-close {
      transform: scale(0);
    }
  }
  .lightbox {

    &-content {
      position: relative;
      z-index: 100;

      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 96vw;
      height: 100%;
      overflow: hidden;

      text-align: center;

      &-item {
        position: relative;
        z-index: 100;

        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;

        text-align: center;

        transition: 0.5s;

        img {
          object-fit: contain;
        }
      }

      &-enter-active,
      &-leave-to {
        opacity: 0;
        transition: czn.transition(var(--transition), (transform, opacity));
      }
      &-enter-from {
        transform: translateX(-10rem);
      }
      &-leave-to {
        transform: translateX(10rem);
      }
    }

    &-caption {
      width: 100%;
      text-align: center;
      color: white;
    }
    &-image {
      object-fit: contain;
      height: 100%;

      transition: 0.5s;
      cursor: zoom-in;
    }

    &-video {
      width: 100%;
      height: 100%;

      > * {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    &-close {
      position: fixed;
      $pos: 1rem;
      top: $pos;
      right: $pos;
      width: czn.$lg * 1.8;
      height: czn.$lg * 1.8;
      z-index: 900;
      padding: 0.2em 0.6em 0.3em 0.3em !important;
      font-size: 1.8rem;
      transform: rotate(45deg);
      border-radius: 50%;
      overflow: hidden;

      transition: var(--transition);
    }

    &-next,
    &-prev {
      position: fixed;
      inset: 0;
      padding: czn.$rg;
      // background: var(--navy-dark);
      // opacity: 0.8;
      font-size: 3rem;
      z-index: 800;
      margin: 0;
      color: var(--primary);

      &:hover,
      &:focus,
      &:active {
        color: var(--emph);
      }
    }
    &-next {
      left: unset;
    }
    &-prev {
      right: unset;
      transform: rotate(180deg);
    }

    &-list {
      position: relative;
      width: 100vw;
      display: flex;
      flex-flow: row;
      flex: 1 0 7vh;
      min-height: 3rem;
      margin-bottom: 2px;
      justify-content: center;
      align-items: flex-end;
      transition: czn.transition(var(--transition), (transform));
      border-bottom: 2px solid var(--secondary);

      .list-image {
        min-width: 4rem;
        min-height: 4rem;
        height: 8vh;
        width: 8vh;
        margin: 0 czn.$rg;
        border-radius: 50%;
        border: 4px solid transparent;
        transition: czn.transition(var(--transition), (border-color));
      }

      .list-item {
        position: relative;
        display: block;
        cursor: pointer;
        &:hover,
        &:focus,
        &.active {
          .list-image {
            border-color: var(--emph);
          }
        }
      }
      
      // .list-item-link {
      //   position: relative;
      //   display: block;
      //   height: 100%;

      //   &::before {
      //     display: block;
      //     height: 100%;
      //     content: '';
      //     padding-left: 100%;
      //   }

      // }

    }
  }
}
</style>
